// import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration
} from "@remix-run/react";
import { json } from "@remix-run/node";
import StoreProvider from "~/commons/utils/state/config/Provider";

// Load the GTM tracking id from the .env
export const loader = async () => {
  return json({});
};

// export const ErrorBoundary = () => {
//   const error = useRouteError();
//   captureRemixErrorBoundaryError(error);
//   return <div>Something went wrong</div>;
// };

export default function App() {
  return (
    <html>
      <head>
        <title>Maestro Scheduler</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <link
          rel="stylesheet"
          href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
        />
        <Meta />
        <Links />
      </head>
      <body>
        <StoreProvider>
          <Outlet />
        </StoreProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}
